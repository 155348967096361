.headerSection {
  padding: 2rem;
  box-shadow: 1px 3px 6px 1px var(--gray-shadow);
}

.headerLink {
  display: block;
}

.title {
  width: 100%;
  max-width: 30rem;
  padding: 0;
  margin: 0 auto;
}

.headerLogo {
  height: auto;
}
